import React from "react"
import Spacer from "../components/spacer/Spacer";
import Centered from "../components/centered/Centered";
import Page from "../components/page/Page";
import "../styles/index.scss";
import InvitationForm from "../components/invitation-form/InvitationForm";

const formStyle = {
  maxWidth: "370px",
  margin: "auto",
}

// markup
const JoinWaitlistPage = () => {
  return (
    <Page>
      <Spacer amount="30" hideOnNarrowDevices={true} />

      <Centered className="max-width-spacing-screen-normal">
        {/* <div className="waitlist-beta">
          <span className="bw-white font-bold text-lead">🌟 Get 1 year FREE of Bravemark Pro on launch 🌟</span><br />
          <span className="yellow-500">Qualify by publishing a #brandpage before the 4th of December.</span>
        </div> */}
        <InvitationForm title="Join the Open Beta" actionLabel="Join the Open Beta!">
          <p className="grey-300">Get early access and exclusive offers while helping us make Bravemark better with your feedback. Designers of all levels are welcome to apply to join. We will quickly verify that you are a graphic designer with your portfolio and send you a link to create your account right away.</p>
        </InvitationForm>
        <p className="grey-300 text-small" style={formStyle}>By signing up you are also accepting to receive our newsletter and marketing emails.</p>
      </Centered>
    </Page>
  )
}


export default JoinWaitlistPage
